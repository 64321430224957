import { ref } from "vue";

export const useSnackBarModule = defineStore("snackbar", () => {
  const showSnack = ref(false);
  const snackText = ref("");
  const snackDetail = ref("");
  const snackIcon = ref("");
  const snackColor = ref("");
  const snackIsBanner = ref(false);

  function open({
    text,
    detail = "",
    icon = "",
    color = "",
    isBanner = false,
  }: {
    text: string;
    detail?: string;
    icon?: string;
    color?: string;
    isBanner?: boolean;
  }) {
    showSnack.value = true;
    snackText.value = text;
    snackDetail.value = detail;
    snackIcon.value = icon;
    snackColor.value = color;
    snackIsBanner.value = isBanner;
  }

  function showError({
    text,
    detail = "",
    isBanner = false,
  }: {
    text: string;
    detail?: string;
    isBanner?: boolean;
  }) {
    showSnack.value = true;
    snackText.value = text;
    snackDetail.value = detail;
    snackIsBanner.value = isBanner;
    snackIcon.value = snackIsBanner ? "close" : "alert-outline";
  }

  function showSuccess({
    text,
    isBanner = false,
  }: {
    text: string;
    isBanner?: boolean;
  }) {
    showSnack.value = true;
    snackText.value = text;
    snackIsBanner.value = isBanner;
    snackIcon.value = snackIsBanner
      ? "information-outline"
      : "checkbox-marked-circle-outline";
  }

  function showInfo({
    text,
    isBanner = false,
  }: {
    text: string;
    isBanner?: boolean;
  }) {
    showSnack.value = true;
    snackText.value = text;
    snackIsBanner.value = isBanner;
    snackIcon.value = "information-outline";
  }

  function close() {
    showSnack.value = false;
    snackText.value = "";
    snackDetail.value = "";
    snackIcon.value = "";
    snackColor.value = "";
    snackIsBanner.value = false;
  }

  return { showSnack, snackText, snackDetail, snackIcon, snackColor, snackIsBanner, open, showError, showSuccess, showInfo, close };
});
