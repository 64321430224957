/**
 * AnybillHeaders class extending the standard Headers class
 * to include custom headers specific to Anybill's API.
 */
export class AnybillHeaders extends Headers {
  /**
   * The total count of entities available on the server.
   * This is useful for pagination to determine the total number of pages.
   */
  get totalCount(): number | null {
    const totalCountHeader = this.get("Total-Count");
    if (totalCountHeader) {
      const parsedCount = Number.parseInt(totalCountHeader, 10);
      return Number.isNaN(parsedCount) ? null : parsedCount;
    }
    return null;
  }

  set totalCount(value: number | null) {
    if (value === null) {
      this.delete("Total-Count");
    }
    else {
      this.set("Total-Count", value.toString());
    }
  }

  /**
   * Creates a new AnybillHeaders object.
   * @param init An optional HeadersInit object to initialize the headers.
   */
  constructor(init?: HeadersInit) {
    super(init);
  }

  /**
   * Overrides the base set method to provide type safety.
   * @param name The name of the header.
   * @param value The value of the header.
   */
  override set(name: string, value: string | number | boolean): void {
    if (typeof value === "number" || typeof value === "boolean") {
      super.set(name, value.toString());
    }
    else {
      super.set(name, value);
    }
  }

  /**
   * Overrides the base append method to provide type safety.
   * @param name The name of the header.
   * @param value The value of the header.
   */
  override append(name: string, value: string | number | boolean): void {
    if (typeof value === "number" || typeof value === "boolean") {
      super.append(name, value.toString());
    }
    else {
      super.append(name, value);
    }
  }
}
