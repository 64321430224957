

export enum BroadcastTypes {
  ENTRANCE_FLOW,
  ENTRANCE_FLOW_POS_DISTRI,
  ENTRANCE_FLOW_SUCCESS,
  ENTRANCE_FLOW_POS_DISTRI_SUCCESS,
  ERROR,
  CROP_REQUIRED,
  ONBOARDING_LINK_SUCCESS,
  PAGE_NEWS,
  PAGE_NEWS_POS_DISTRI,
}

export const useBroadcastModule = defineStore("broadcast", () => {
  const isOpen = ref(false);
  const type = ref<BroadcastTypes | null>(null);
  const args = ref(null);

  function show({ newType, newArgs }: { newType: BroadcastTypes; newArgs: any }) {
    type.value = newType;
    args.value = newArgs;
    isOpen.value = true;
  }

  function close() {
    type.value = null;
    args.value = null;
    isOpen.value = false;
  }

  return { isOpen, type, args, show, close };
});
