
import type Notification from "~/additionalModels/Notification";

export const useNotificationModule = defineStore("notification", () => {
  const notifications = ref<Notification[]>([]);
  const dynamicComp = ref(null);

  function addNotification(toAdd: Notification) {
    notifications.value = notifications.value.concat(toAdd);
  }

  function removeNotification(index: number) {
    delete notifications.value[index];
  }

  return { notifications, dynamicComp, addNotification, removeNotification };
});
