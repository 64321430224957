
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as icons_45pagerRFhlv8b97Meta } from "/app/anybill-partner-portal-v3/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue?macro=true";
import { default as indexVY3str7LLuMeta } from "/app/anybill-partner-portal-v3/pages/activation/index.vue?macro=true";
import { default as ReceiptsExportDialog7ku5unsXiSMeta } from "/app/anybill-partner-portal-v3/pages/archive/components/ReceiptsExportDialog.vue?macro=true";
import { default as ReceiptsFilterDialoguQjMM0ww6TMeta } from "/app/anybill-partner-portal-v3/pages/archive/components/ReceiptsFilterDialog.vue?macro=true";
import { default as RFStoreComponent2Y0LBXKJt9Meta } from "/app/anybill-partner-portal-v3/pages/archive/components/RFStoreComponent.vue?macro=true";
import { default as defaultViewy2AXycr0KXMeta } from "/app/anybill-partner-portal-v3/pages/archive/defaultView.vue?macro=true";
import { default as exportsyWzPrU2DVOMeta } from "/app/anybill-partner-portal-v3/pages/archive/exports.vue?macro=true";
import { default as receipts1gQeuHFW4fMeta } from "/app/anybill-partner-portal-v3/pages/archive/receipts.vue?macro=true";
import { default as ApiUserDataCard4VGIN8auNJMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/ApiUserDataCard.vue?macro=true";
import { default as CustomerOverviewCard0PWS345T3WMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/CustomerOverviewCard.vue?macro=true";
import { default as AUDConfirmDeleteDialogJUhDZ3hGCIMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/dialogs/AUDConfirmDeleteDialog.vue?macro=true";
import { default as AUDCreateApiUserDialogDjdRA6NfRrMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/dialogs/AUDCreateApiUserDialog.vue?macro=true";
import { default as COCCustomerEditDialogrua0Wpk2UqMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/dialogs/COCCustomerEditDialog.vue?macro=true";
import { default as StoreDataCardDxCr9RNcNiMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/StoreDataCard.vue?macro=true";
import { default as indexAqY5n9YaViMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/[id]/index.vue?macro=true";
import { default as CustomerManagementTableCarde8OwrXBVDFMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/components/CustomerManagementTableCard.vue?macro=true";
import { default as DevBulkRemoveDialogWlV5CVz77KMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/components/dialogs/developer/DevBulkRemoveDialog.vue?macro=true";
import { default as OnboardingTableCardGBBJdUQSMEMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/components/OnboardingTableCard.vue?macro=true";
import { default as indexKd3DktJiMdMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/index.vue?macro=true";
import { default as CmProfileA1yEb0XHU2Meta } from "/app/anybill-partner-portal-v3/pages/customer-management/onboarding/CmProfile.vue?macro=true";
import { default as CMStoresvLMfgnrVW1Meta } from "/app/anybill-partner-portal-v3/pages/customer-management/onboarding/CMStores.vue?macro=true";
import { default as CmSuccessDialog5s7MWO8mjUMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/onboarding/CmSuccessDialog.vue?macro=true";
import { default as CreateIndividualLinkDialogLKCmUStbu4Meta } from "/app/anybill-partner-portal-v3/pages/customer-management/onboarding/CreateIndividualLinkDialog.vue?macro=true";
import { default as CreateLinkDialogPRtnyACPyAMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/onboarding/CreateLinkDialog.vue?macro=true";
import { default as DeleteOnboardingDialogftMKxuaG2EMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/onboarding/DeleteOnboardingDialog.vue?macro=true";
import { default as ImageUploaderWhGD29AYgGMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/onboarding/ImageUploader.vue?macro=true";
import { default as SelectOnboardingMethodDialog56W7bwbaMSMeta } from "/app/anybill-partner-portal-v3/pages/customer-management/onboarding/SelectOnboardingMethodDialog.vue?macro=true";
import { default as CalendarSelectionCardaNHdbXZVGqMeta } from "/app/anybill-partner-portal-v3/pages/dashboard/components/CalendarSelectionCard.vue?macro=true";
import { default as DisplayAdClicksCardQ878Az4OPnMeta } from "/app/anybill-partner-portal-v3/pages/dashboard/components/cards/DisplayAdClicksCard.vue?macro=true";
import { default as DisplayAdImpressionsCardhT4679g1T6Meta } from "/app/anybill-partner-portal-v3/pages/dashboard/components/cards/DisplayAdImpressionsCard.vue?macro=true";
import { default as EnvironmentCardWLzRoC2XOnMeta } from "/app/anybill-partner-portal-v3/pages/dashboard/components/cards/EnvironmentCard.vue?macro=true";
import { default as NewsletterCardtrjKc7fTRrMeta } from "/app/anybill-partner-portal-v3/pages/dashboard/components/cards/NewsletterCard.vue?macro=true";
import { default as OverallBillsCard3E2oswIMaZMeta } from "/app/anybill-partner-portal-v3/pages/dashboard/components/cards/OverallBillsCard.vue?macro=true";
import { default as PickupRateCard1py2NmL8o6Meta } from "/app/anybill-partner-portal-v3/pages/dashboard/components/cards/PickupRateCard.vue?macro=true";
import { default as PrintedReceiptsCardVmupI378E0Meta } from "/app/anybill-partner-portal-v3/pages/dashboard/components/cards/PrintedReceiptsCard.vue?macro=true";
import { default as SavedCostCardfUplgslN1dMeta } from "/app/anybill-partner-portal-v3/pages/dashboard/components/cards/SavedCostCard.vue?macro=true";
import { default as CSCustomerFilterAreaOJuWTLPlpOMeta } from "/app/anybill-partner-portal-v3/pages/dashboard/components/company_structure/CSCustomerFilterArea.vue?macro=true";
import { default as CSStoreFilterMenunFht6Y2pl8Meta } from "/app/anybill-partner-portal-v3/pages/dashboard/components/company_structure/CSStoreFilterMenu.vue?macro=true";
import { default as GenericDashboardCardQNiMymm9tXMeta } from "/app/anybill-partner-portal-v3/pages/dashboard/components/GenericDashboardCard.vue?macro=true";
import { default as StoreSelectionFilterMenurtgxeCzuAHMeta } from "/app/anybill-partner-portal-v3/pages/dashboard/components/StoreSelectionFilterMenu.vue?macro=true";
import { default as VendorCustomerFilterMenuGkF1U6IXQzMeta } from "/app/anybill-partner-portal-v3/pages/dashboard/components/VendorCustomerFilterMenu.vue?macro=true";
import { default as indexNHxcIQcSUMMeta } from "/app/anybill-partner-portal-v3/pages/dashboard/index.vue?macro=true";
import { default as indexpvqpfwwnEpMeta } from "/app/anybill-partner-portal-v3/pages/distributor-management/index.vue?macro=true";
import { default as SuccessDialolgCRBy1isJB4Meta } from "/app/anybill-partner-portal-v3/pages/distributor-management/SuccessDialolg.vue?macro=true";
import { default as indexU5JQntZFlVMeta } from "/app/anybill-partner-portal-v3/pages/documents/index.vue?macro=true";
import { default as index48k5wZRNVmMeta } from "/app/anybill-partner-portal-v3/pages/index.vue?macro=true";
import { default as indexh31L96ffG1Meta } from "/app/anybill-partner-portal-v3/pages/login/index.vue?macro=true";
import { default as ContentManagementOverviewc7nprs6BYhMeta } from "/app/anybill-partner-portal-v3/pages/marketing/content-management/components/ContentManagementOverview.vue?macro=true";
import { default as AdvancedSettingsArea9Ha8qnSqdOMeta } from "/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/advanced-settings/AdvancedSettingsArea.vue?macro=true";
import { default as ASChannelComponentmGUhBjKQxTMeta } from "/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/advanced-settings/ASChannelComponent.vue?macro=true";
import { default as ASPeriodComponentYWflPq4xxeMeta } from "/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/advanced-settings/ASPeriodComponent.vue?macro=true";
import { default as ASPositionComponentB4wSGNaNA3Meta } from "/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/advanced-settings/ASPositionComponent.vue?macro=true";
import { default as ASStoresComponentS6zjofvmdjMeta } from "/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/advanced-settings/ASStoresComponent.vue?macro=true";
import { default as CMOBasicPackageDialogtDne4t8nqYMeta } from "/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMOBasicPackageDialog.vue?macro=true";
import { default as CMOConflictDialogQWBHa87iqMMeta } from "/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMOConflictDialog.vue?macro=true";
import { default as CMODeactivationDialogQ38CVSaidUMeta } from "/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMODeactivationDialog.vue?macro=true";
import { default as CMODialogqCF9U2Ms5MMeta } from "/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMODialog.vue?macro=true";
import { default as CMODialogExtendedOptionTileJyEgx0OpVRMeta } from "/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMODialogExtendedOptionTile.vue?macro=true";
import { default as CMODialogRadioComponentBB2qqCz7bnMeta } from "/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMODialogRadioComponent.vue?macro=true";
import { default as DAPGroupContentQt2kRP3BJSMeta } from "/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/preview/DAPGroupContent.vue?macro=true";
import { default as DisplayAdPreviewDialoggFUygnK8gnMeta } from "/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/preview/DisplayAdPreviewDialog.vue?macro=true";
import { default as indexqFcnEm6LmwMeta } from "/app/anybill-partner-portal-v3/pages/marketing/content-management/index.vue?macro=true";
import { default as customer_45mailsqZtLsmGdTjMeta } from "/app/anybill-partner-portal-v3/pages/marketing/customer-mails.vue?macro=true";
import { default as indexvbi9oftJbrMeta } from "/app/anybill-partner-portal-v3/pages/onboard/index.vue?macro=true";
import { default as BookingDialogOzxjSvfd8OMeta } from "/app/anybill-partner-portal-v3/pages/packages/components/dialogs/BookingDialog.vue?macro=true";
import { default as BookingSuccessDialoguaQqnVm6ONMeta } from "/app/anybill-partner-portal-v3/pages/packages/components/dialogs/BookingSuccessDialog.vue?macro=true";
import { default as PackagesAddressDialoghzD7mhPKJrMeta } from "/app/anybill-partner-portal-v3/pages/packages/components/dialogs/PackagesAddressDialog.vue?macro=true";
import { default as PackagesChangePaymentDialogRIO3WKq2TWMeta } from "/app/anybill-partner-portal-v3/pages/packages/components/dialogs/PackagesChangePaymentDialog.vue?macro=true";
import { default as PackagesFeatureListo0NWZeZFUaMeta } from "/app/anybill-partner-portal-v3/pages/packages/components/dialogs/PackagesFeatureList.vue?macro=true";
import { default as IndividualPricingCard5QbuV9GN36Meta } from "/app/anybill-partner-portal-v3/pages/packages/components/table/content/IndividualPricingCard.vue?macro=true";
import { default as PricingComparisonTablebL6HQ6vSLwMeta } from "/app/anybill-partner-portal-v3/pages/packages/components/table/PricingComparisonTable.vue?macro=true";
import { default as PricingToggleSwitchurtGOIQsO8Meta } from "/app/anybill-partner-portal-v3/pages/packages/components/table/PricingToggleSwitch.vue?macro=true";
import { default as indexINR7CVYuoZMeta } from "/app/anybill-partner-portal-v3/pages/packages/index.vue?macro=true";
import { default as AnybillLogo8gJv9pCg2RMeta } from "/app/anybill-partner-portal-v3/pages/privacy/components/AnybillLogo.vue?macro=true";
import { default as PrivacyContentDeJh3dMbDqjrMeta } from "/app/anybill-partner-portal-v3/pages/privacy/components/PrivacyContentDe.vue?macro=true";
import { default as PrivacyContentEnst0CRPG3sUMeta } from "/app/anybill-partner-portal-v3/pages/privacy/components/PrivacyContentEn.vue?macro=true";
import { default as indexnewfnpMSUPMeta } from "/app/anybill-partner-portal-v3/pages/privacy/index.vue?macro=true";
import { default as AddressProfileCardx2N7GqFS1WMeta } from "/app/anybill-partner-portal-v3/pages/profile/components/AddressProfileCard.vue?macro=true";
import { default as VendorDialog4oZ6MGiynZMeta } from "/app/anybill-partner-portal-v3/pages/profile/components/dialogs/VendorDialog.vue?macro=true";
import { default as PaymentProfileCardhI6TYOToL5Meta } from "/app/anybill-partner-portal-v3/pages/profile/components/PaymentProfileCard.vue?macro=true";
import { default as VendorProfileCard7I1hEJaHGaMeta } from "/app/anybill-partner-portal-v3/pages/profile/components/VendorProfileCard.vue?macro=true";
import { default as indexTT1mCcF2JHMeta } from "/app/anybill-partner-portal-v3/pages/profile/index.vue?macro=true";
import { default as ChangePasswordDialogsTUvbBdhfPMeta } from "/app/anybill-partner-portal-v3/pages/settings/components/dialogs/ChangePasswordDialog.vue?macro=true";
import { default as SettingsPage7sSY9HNk1eMeta } from "/app/anybill-partner-portal-v3/pages/settings/components/SettingsPage.vue?macro=true";
import { default as index6qPAaaCFS2Meta } from "/app/anybill-partner-portal-v3/pages/settings/index.vue?macro=true";
import { default as AddStoreDialogh8EeTlxqEJMeta } from "/app/anybill-partner-portal-v3/pages/store-management/dialogs/AddStoreDialog.vue?macro=true";
import { default as Step1PhXipTk1sdMeta } from "/app/anybill-partner-portal-v3/pages/store-management/dialogs/Step1.vue?macro=true";
import { default as Step2eFD2fbijLxMeta } from "/app/anybill-partner-portal-v3/pages/store-management/dialogs/Step2.vue?macro=true";
import { default as Step3mgHyEafFfqMeta } from "/app/anybill-partner-portal-v3/pages/store-management/dialogs/Step3.vue?macro=true";
import { default as Step4r1VT0861gSMeta } from "/app/anybill-partner-portal-v3/pages/store-management/dialogs/Step4.vue?macro=true";
import { default as indexhYF2GEGFAZMeta } from "/app/anybill-partner-portal-v3/pages/store-management/index.vue?macro=true";
import { default as EditRightsGroupsDialogQEEHnxGw7FMeta } from "/app/anybill-partner-portal-v3/pages/user-administration/dialogs/EditRightsGroupsDialog.vue?macro=true";
import { default as EditUserRightsDialogW7bfgADEokMeta } from "/app/anybill-partner-portal-v3/pages/user-administration/dialogs/EditUserRightsDialog.vue?macro=true";
import { default as indexXhAEDt5WGRMeta } from "/app/anybill-partner-portal-v3/pages/user-administration/index.vue?macro=true";
export default [
  {
    name: "icons-page",
    path: "/_icons",
    meta: {"layout":"svg-sprite"},
    component: () => import("/app/anybill-partner-portal-v3/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue")
  },
  {
    name: "activation___en",
    path: "/en/activation",
    component: () => import("/app/anybill-partner-portal-v3/pages/activation/index.vue")
  },
  {
    name: "activation___de",
    path: "/activation",
    component: () => import("/app/anybill-partner-portal-v3/pages/activation/index.vue")
  },
  {
    name: "archive-components-ReceiptsExportDialog___en",
    path: "/en/archive/components/ReceiptsExportDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/archive/components/ReceiptsExportDialog.vue")
  },
  {
    name: "archive-components-ReceiptsExportDialog___de",
    path: "/archive/components/ReceiptsExportDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/archive/components/ReceiptsExportDialog.vue")
  },
  {
    name: "archive-components-ReceiptsFilterDialog___en",
    path: "/en/archive/components/ReceiptsFilterDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/archive/components/ReceiptsFilterDialog.vue")
  },
  {
    name: "archive-components-ReceiptsFilterDialog___de",
    path: "/archive/components/ReceiptsFilterDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/archive/components/ReceiptsFilterDialog.vue")
  },
  {
    name: "archive-components-RFStoreComponent___en",
    path: "/en/archive/components/RFStoreComponent",
    component: () => import("/app/anybill-partner-portal-v3/pages/archive/components/RFStoreComponent.vue")
  },
  {
    name: "archive-components-RFStoreComponent___de",
    path: "/archive/components/RFStoreComponent",
    component: () => import("/app/anybill-partner-portal-v3/pages/archive/components/RFStoreComponent.vue")
  },
  {
    name: "archive-defaultView___en",
    path: "/en/archive/defaultView",
    component: () => import("/app/anybill-partner-portal-v3/pages/archive/defaultView.vue")
  },
  {
    name: "archive-defaultView___de",
    path: "/archive/defaultView",
    component: () => import("/app/anybill-partner-portal-v3/pages/archive/defaultView.vue")
  },
  {
    name: "archive-exports___en",
    path: "/en/archive/exports",
    component: () => import("/app/anybill-partner-portal-v3/pages/archive/exports.vue")
  },
  {
    name: "archive-exports___de",
    path: "/archive/exports",
    component: () => import("/app/anybill-partner-portal-v3/pages/archive/exports.vue")
  },
  {
    name: "archive-receipts___en",
    path: "/en/archive/receipts",
    component: () => import("/app/anybill-partner-portal-v3/pages/archive/receipts.vue")
  },
  {
    name: "archive-receipts___de",
    path: "/archive/receipts",
    component: () => import("/app/anybill-partner-portal-v3/pages/archive/receipts.vue")
  },
  {
    name: "customer-management-id-components-ApiUserDataCard___en",
    path: "/en/customer-management/:id()/components/ApiUserDataCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/ApiUserDataCard.vue")
  },
  {
    name: "customer-management-id-components-ApiUserDataCard___de",
    path: "/customer-management/:id()/components/ApiUserDataCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/ApiUserDataCard.vue")
  },
  {
    name: "customer-management-id-components-CustomerOverviewCard___en",
    path: "/en/customer-management/:id()/components/CustomerOverviewCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/CustomerOverviewCard.vue")
  },
  {
    name: "customer-management-id-components-CustomerOverviewCard___de",
    path: "/customer-management/:id()/components/CustomerOverviewCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/CustomerOverviewCard.vue")
  },
  {
    name: "customer-management-id-components-dialogs-AUDConfirmDeleteDialog___en",
    path: "/en/customer-management/:id()/components/dialogs/AUDConfirmDeleteDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/dialogs/AUDConfirmDeleteDialog.vue")
  },
  {
    name: "customer-management-id-components-dialogs-AUDConfirmDeleteDialog___de",
    path: "/customer-management/:id()/components/dialogs/AUDConfirmDeleteDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/dialogs/AUDConfirmDeleteDialog.vue")
  },
  {
    name: "customer-management-id-components-dialogs-AUDCreateApiUserDialog___en",
    path: "/en/customer-management/:id()/components/dialogs/AUDCreateApiUserDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/dialogs/AUDCreateApiUserDialog.vue")
  },
  {
    name: "customer-management-id-components-dialogs-AUDCreateApiUserDialog___de",
    path: "/customer-management/:id()/components/dialogs/AUDCreateApiUserDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/dialogs/AUDCreateApiUserDialog.vue")
  },
  {
    name: "customer-management-id-components-dialogs-COCCustomerEditDialog___en",
    path: "/en/customer-management/:id()/components/dialogs/COCCustomerEditDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/dialogs/COCCustomerEditDialog.vue")
  },
  {
    name: "customer-management-id-components-dialogs-COCCustomerEditDialog___de",
    path: "/customer-management/:id()/components/dialogs/COCCustomerEditDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/dialogs/COCCustomerEditDialog.vue")
  },
  {
    name: "customer-management-id-components-StoreDataCard___en",
    path: "/en/customer-management/:id()/components/StoreDataCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/StoreDataCard.vue")
  },
  {
    name: "customer-management-id-components-StoreDataCard___de",
    path: "/customer-management/:id()/components/StoreDataCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/[id]/components/StoreDataCard.vue")
  },
  {
    name: "customer-management-id___en",
    path: "/en/customer-management/:id()",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/[id]/index.vue")
  },
  {
    name: "customer-management-id___de",
    path: "/customer-management/:id()",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/[id]/index.vue")
  },
  {
    name: "customer-management-components-CustomerManagementTableCard___en",
    path: "/en/customer-management/components/CustomerManagementTableCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/components/CustomerManagementTableCard.vue")
  },
  {
    name: "customer-management-components-CustomerManagementTableCard___de",
    path: "/customer-management/components/CustomerManagementTableCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/components/CustomerManagementTableCard.vue")
  },
  {
    name: "customer-management-components-dialogs-developer-DevBulkRemoveDialog___en",
    path: "/en/customer-management/components/dialogs/developer/DevBulkRemoveDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/components/dialogs/developer/DevBulkRemoveDialog.vue")
  },
  {
    name: "customer-management-components-dialogs-developer-DevBulkRemoveDialog___de",
    path: "/customer-management/components/dialogs/developer/DevBulkRemoveDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/components/dialogs/developer/DevBulkRemoveDialog.vue")
  },
  {
    name: "customer-management-components-OnboardingTableCard___en",
    path: "/en/customer-management/components/OnboardingTableCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/components/OnboardingTableCard.vue")
  },
  {
    name: "customer-management-components-OnboardingTableCard___de",
    path: "/customer-management/components/OnboardingTableCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/components/OnboardingTableCard.vue")
  },
  {
    name: "customer-management___en",
    path: "/en/customer-management",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/index.vue")
  },
  {
    name: "customer-management___de",
    path: "/customer-management",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/index.vue")
  },
  {
    name: "customer-management-onboarding-CmProfile___en",
    path: "/en/customer-management/onboarding/CmProfile",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/CmProfile.vue")
  },
  {
    name: "customer-management-onboarding-CmProfile___de",
    path: "/customer-management/onboarding/CmProfile",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/CmProfile.vue")
  },
  {
    name: "customer-management-onboarding-CMStores___en",
    path: "/en/customer-management/onboarding/CMStores",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/CMStores.vue")
  },
  {
    name: "customer-management-onboarding-CMStores___de",
    path: "/customer-management/onboarding/CMStores",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/CMStores.vue")
  },
  {
    name: "customer-management-onboarding-CmSuccessDialog___en",
    path: "/en/customer-management/onboarding/CmSuccessDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/CmSuccessDialog.vue")
  },
  {
    name: "customer-management-onboarding-CmSuccessDialog___de",
    path: "/customer-management/onboarding/CmSuccessDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/CmSuccessDialog.vue")
  },
  {
    name: "customer-management-onboarding-CreateIndividualLinkDialog___en",
    path: "/en/customer-management/onboarding/CreateIndividualLinkDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/CreateIndividualLinkDialog.vue")
  },
  {
    name: "customer-management-onboarding-CreateIndividualLinkDialog___de",
    path: "/customer-management/onboarding/CreateIndividualLinkDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/CreateIndividualLinkDialog.vue")
  },
  {
    name: "customer-management-onboarding-CreateLinkDialog___en",
    path: "/en/customer-management/onboarding/CreateLinkDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/CreateLinkDialog.vue")
  },
  {
    name: "customer-management-onboarding-CreateLinkDialog___de",
    path: "/customer-management/onboarding/CreateLinkDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/CreateLinkDialog.vue")
  },
  {
    name: "customer-management-onboarding-DeleteOnboardingDialog___en",
    path: "/en/customer-management/onboarding/DeleteOnboardingDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/DeleteOnboardingDialog.vue")
  },
  {
    name: "customer-management-onboarding-DeleteOnboardingDialog___de",
    path: "/customer-management/onboarding/DeleteOnboardingDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/DeleteOnboardingDialog.vue")
  },
  {
    name: "customer-management-onboarding-ImageUploader___en",
    path: "/en/customer-management/onboarding/ImageUploader",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/ImageUploader.vue")
  },
  {
    name: "customer-management-onboarding-ImageUploader___de",
    path: "/customer-management/onboarding/ImageUploader",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/ImageUploader.vue")
  },
  {
    name: "customer-management-onboarding-SelectOnboardingMethodDialog___en",
    path: "/en/customer-management/onboarding/SelectOnboardingMethodDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/SelectOnboardingMethodDialog.vue")
  },
  {
    name: "customer-management-onboarding-SelectOnboardingMethodDialog___de",
    path: "/customer-management/onboarding/SelectOnboardingMethodDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/customer-management/onboarding/SelectOnboardingMethodDialog.vue")
  },
  {
    name: "dashboard-components-CalendarSelectionCard___en",
    path: "/en/dashboard/components/CalendarSelectionCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/CalendarSelectionCard.vue")
  },
  {
    name: "dashboard-components-CalendarSelectionCard___de",
    path: "/dashboard/components/CalendarSelectionCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/CalendarSelectionCard.vue")
  },
  {
    name: "dashboard-components-cards-DisplayAdClicksCard___en",
    path: "/en/dashboard/components/cards/DisplayAdClicksCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/cards/DisplayAdClicksCard.vue")
  },
  {
    name: "dashboard-components-cards-DisplayAdClicksCard___de",
    path: "/dashboard/components/cards/DisplayAdClicksCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/cards/DisplayAdClicksCard.vue")
  },
  {
    name: "dashboard-components-cards-DisplayAdImpressionsCard___en",
    path: "/en/dashboard/components/cards/DisplayAdImpressionsCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/cards/DisplayAdImpressionsCard.vue")
  },
  {
    name: "dashboard-components-cards-DisplayAdImpressionsCard___de",
    path: "/dashboard/components/cards/DisplayAdImpressionsCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/cards/DisplayAdImpressionsCard.vue")
  },
  {
    name: "dashboard-components-cards-EnvironmentCard___en",
    path: "/en/dashboard/components/cards/EnvironmentCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/cards/EnvironmentCard.vue")
  },
  {
    name: "dashboard-components-cards-EnvironmentCard___de",
    path: "/dashboard/components/cards/EnvironmentCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/cards/EnvironmentCard.vue")
  },
  {
    name: "dashboard-components-cards-NewsletterCard___en",
    path: "/en/dashboard/components/cards/NewsletterCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/cards/NewsletterCard.vue")
  },
  {
    name: "dashboard-components-cards-NewsletterCard___de",
    path: "/dashboard/components/cards/NewsletterCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/cards/NewsletterCard.vue")
  },
  {
    name: "dashboard-components-cards-OverallBillsCard___en",
    path: "/en/dashboard/components/cards/OverallBillsCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/cards/OverallBillsCard.vue")
  },
  {
    name: "dashboard-components-cards-OverallBillsCard___de",
    path: "/dashboard/components/cards/OverallBillsCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/cards/OverallBillsCard.vue")
  },
  {
    name: "dashboard-components-cards-PickupRateCard___en",
    path: "/en/dashboard/components/cards/PickupRateCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/cards/PickupRateCard.vue")
  },
  {
    name: "dashboard-components-cards-PickupRateCard___de",
    path: "/dashboard/components/cards/PickupRateCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/cards/PickupRateCard.vue")
  },
  {
    name: "dashboard-components-cards-PrintedReceiptsCard___en",
    path: "/en/dashboard/components/cards/PrintedReceiptsCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/cards/PrintedReceiptsCard.vue")
  },
  {
    name: "dashboard-components-cards-PrintedReceiptsCard___de",
    path: "/dashboard/components/cards/PrintedReceiptsCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/cards/PrintedReceiptsCard.vue")
  },
  {
    name: "dashboard-components-cards-SavedCostCard___en",
    path: "/en/dashboard/components/cards/SavedCostCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/cards/SavedCostCard.vue")
  },
  {
    name: "dashboard-components-cards-SavedCostCard___de",
    path: "/dashboard/components/cards/SavedCostCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/cards/SavedCostCard.vue")
  },
  {
    name: "dashboard-components-company_structure-CSCustomerFilterArea___en",
    path: "/en/dashboard/components/company_structure/CSCustomerFilterArea",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/company_structure/CSCustomerFilterArea.vue")
  },
  {
    name: "dashboard-components-company_structure-CSCustomerFilterArea___de",
    path: "/dashboard/components/company_structure/CSCustomerFilterArea",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/company_structure/CSCustomerFilterArea.vue")
  },
  {
    name: "dashboard-components-company_structure-CSStoreFilterMenu___en",
    path: "/en/dashboard/components/company_structure/CSStoreFilterMenu",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/company_structure/CSStoreFilterMenu.vue")
  },
  {
    name: "dashboard-components-company_structure-CSStoreFilterMenu___de",
    path: "/dashboard/components/company_structure/CSStoreFilterMenu",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/company_structure/CSStoreFilterMenu.vue")
  },
  {
    name: "dashboard-components-GenericDashboardCard___en",
    path: "/en/dashboard/components/GenericDashboardCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/GenericDashboardCard.vue")
  },
  {
    name: "dashboard-components-GenericDashboardCard___de",
    path: "/dashboard/components/GenericDashboardCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/GenericDashboardCard.vue")
  },
  {
    name: "dashboard-components-StoreSelectionFilterMenu___en",
    path: "/en/dashboard/components/StoreSelectionFilterMenu",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/StoreSelectionFilterMenu.vue")
  },
  {
    name: "dashboard-components-StoreSelectionFilterMenu___de",
    path: "/dashboard/components/StoreSelectionFilterMenu",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/StoreSelectionFilterMenu.vue")
  },
  {
    name: "dashboard-components-VendorCustomerFilterMenu___en",
    path: "/en/dashboard/components/VendorCustomerFilterMenu",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/VendorCustomerFilterMenu.vue")
  },
  {
    name: "dashboard-components-VendorCustomerFilterMenu___de",
    path: "/dashboard/components/VendorCustomerFilterMenu",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/components/VendorCustomerFilterMenu.vue")
  },
  {
    name: "dashboard___en",
    path: "/en/dashboard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/index.vue")
  },
  {
    name: "dashboard___de",
    path: "/dashboard",
    component: () => import("/app/anybill-partner-portal-v3/pages/dashboard/index.vue")
  },
  {
    name: "distributor-management___en",
    path: "/en/distributor-management",
    component: () => import("/app/anybill-partner-portal-v3/pages/distributor-management/index.vue")
  },
  {
    name: "distributor-management___de",
    path: "/distributor-management",
    component: () => import("/app/anybill-partner-portal-v3/pages/distributor-management/index.vue")
  },
  {
    name: "distributor-management-SuccessDialolg___en",
    path: "/en/distributor-management/SuccessDialolg",
    component: () => import("/app/anybill-partner-portal-v3/pages/distributor-management/SuccessDialolg.vue")
  },
  {
    name: "distributor-management-SuccessDialolg___de",
    path: "/distributor-management/SuccessDialolg",
    component: () => import("/app/anybill-partner-portal-v3/pages/distributor-management/SuccessDialolg.vue")
  },
  {
    name: "documents___en",
    path: "/en/documents",
    component: () => import("/app/anybill-partner-portal-v3/pages/documents/index.vue")
  },
  {
    name: "documents___de",
    path: "/documents",
    component: () => import("/app/anybill-partner-portal-v3/pages/documents/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: index48k5wZRNVmMeta || {},
    component: () => import("/app/anybill-partner-portal-v3/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/",
    meta: index48k5wZRNVmMeta || {},
    component: () => import("/app/anybill-partner-portal-v3/pages/index.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    component: () => import("/app/anybill-partner-portal-v3/pages/login/index.vue")
  },
  {
    name: "login___de",
    path: "/login",
    component: () => import("/app/anybill-partner-portal-v3/pages/login/index.vue")
  },
  {
    name: "marketing-content-management-components-ContentManagementOverview___en",
    path: "/en/marketing/content-management/components/ContentManagementOverview",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/ContentManagementOverview.vue")
  },
  {
    name: "marketing-content-management-components-ContentManagementOverview___de",
    path: "/marketing/content-management/components/ContentManagementOverview",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/ContentManagementOverview.vue")
  },
  {
    name: "marketing-content-management-components-dialogs-advanced-settings-AdvancedSettingsArea___en",
    path: "/en/marketing/content-management/components/dialogs/advanced-settings/AdvancedSettingsArea",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/advanced-settings/AdvancedSettingsArea.vue")
  },
  {
    name: "marketing-content-management-components-dialogs-advanced-settings-AdvancedSettingsArea___de",
    path: "/marketing/content-management/components/dialogs/advanced-settings/AdvancedSettingsArea",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/advanced-settings/AdvancedSettingsArea.vue")
  },
  {
    name: "marketing-content-management-components-dialogs-advanced-settings-ASChannelComponent___en",
    path: "/en/marketing/content-management/components/dialogs/advanced-settings/ASChannelComponent",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/advanced-settings/ASChannelComponent.vue")
  },
  {
    name: "marketing-content-management-components-dialogs-advanced-settings-ASChannelComponent___de",
    path: "/marketing/content-management/components/dialogs/advanced-settings/ASChannelComponent",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/advanced-settings/ASChannelComponent.vue")
  },
  {
    name: "marketing-content-management-components-dialogs-advanced-settings-ASPeriodComponent___en",
    path: "/en/marketing/content-management/components/dialogs/advanced-settings/ASPeriodComponent",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/advanced-settings/ASPeriodComponent.vue")
  },
  {
    name: "marketing-content-management-components-dialogs-advanced-settings-ASPeriodComponent___de",
    path: "/marketing/content-management/components/dialogs/advanced-settings/ASPeriodComponent",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/advanced-settings/ASPeriodComponent.vue")
  },
  {
    name: "marketing-content-management-components-dialogs-advanced-settings-ASPositionComponent___en",
    path: "/en/marketing/content-management/components/dialogs/advanced-settings/ASPositionComponent",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/advanced-settings/ASPositionComponent.vue")
  },
  {
    name: "marketing-content-management-components-dialogs-advanced-settings-ASPositionComponent___de",
    path: "/marketing/content-management/components/dialogs/advanced-settings/ASPositionComponent",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/advanced-settings/ASPositionComponent.vue")
  },
  {
    name: "marketing-content-management-components-dialogs-advanced-settings-ASStoresComponent___en",
    path: "/en/marketing/content-management/components/dialogs/advanced-settings/ASStoresComponent",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/advanced-settings/ASStoresComponent.vue")
  },
  {
    name: "marketing-content-management-components-dialogs-advanced-settings-ASStoresComponent___de",
    path: "/marketing/content-management/components/dialogs/advanced-settings/ASStoresComponent",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/advanced-settings/ASStoresComponent.vue")
  },
  {
    name: "marketing-content-management-components-dialogs-CMOBasicPackageDialog___en",
    path: "/en/marketing/content-management/components/dialogs/CMOBasicPackageDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMOBasicPackageDialog.vue")
  },
  {
    name: "marketing-content-management-components-dialogs-CMOBasicPackageDialog___de",
    path: "/marketing/content-management/components/dialogs/CMOBasicPackageDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMOBasicPackageDialog.vue")
  },
  {
    name: "marketing-content-management-components-dialogs-CMOConflictDialog___en",
    path: "/en/marketing/content-management/components/dialogs/CMOConflictDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMOConflictDialog.vue")
  },
  {
    name: "marketing-content-management-components-dialogs-CMOConflictDialog___de",
    path: "/marketing/content-management/components/dialogs/CMOConflictDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMOConflictDialog.vue")
  },
  {
    name: "marketing-content-management-components-dialogs-CMODeactivationDialog___en",
    path: "/en/marketing/content-management/components/dialogs/CMODeactivationDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMODeactivationDialog.vue")
  },
  {
    name: "marketing-content-management-components-dialogs-CMODeactivationDialog___de",
    path: "/marketing/content-management/components/dialogs/CMODeactivationDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMODeactivationDialog.vue")
  },
  {
    name: "marketing-content-management-components-dialogs-CMODialog___en",
    path: "/en/marketing/content-management/components/dialogs/CMODialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMODialog.vue")
  },
  {
    name: "marketing-content-management-components-dialogs-CMODialog___de",
    path: "/marketing/content-management/components/dialogs/CMODialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMODialog.vue")
  },
  {
    name: "marketing-content-management-components-dialogs-CMODialogExtendedOptionTile___en",
    path: "/en/marketing/content-management/components/dialogs/CMODialogExtendedOptionTile",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMODialogExtendedOptionTile.vue")
  },
  {
    name: "marketing-content-management-components-dialogs-CMODialogExtendedOptionTile___de",
    path: "/marketing/content-management/components/dialogs/CMODialogExtendedOptionTile",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMODialogExtendedOptionTile.vue")
  },
  {
    name: "marketing-content-management-components-dialogs-CMODialogRadioComponent___en",
    path: "/en/marketing/content-management/components/dialogs/CMODialogRadioComponent",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMODialogRadioComponent.vue")
  },
  {
    name: "marketing-content-management-components-dialogs-CMODialogRadioComponent___de",
    path: "/marketing/content-management/components/dialogs/CMODialogRadioComponent",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/CMODialogRadioComponent.vue")
  },
  {
    name: "marketing-content-management-components-dialogs-preview-DAPGroupContent___en",
    path: "/en/marketing/content-management/components/dialogs/preview/DAPGroupContent",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/preview/DAPGroupContent.vue")
  },
  {
    name: "marketing-content-management-components-dialogs-preview-DAPGroupContent___de",
    path: "/marketing/content-management/components/dialogs/preview/DAPGroupContent",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/preview/DAPGroupContent.vue")
  },
  {
    name: "marketing-content-management-components-dialogs-preview-DisplayAdPreviewDialog___en",
    path: "/en/marketing/content-management/components/dialogs/preview/DisplayAdPreviewDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/preview/DisplayAdPreviewDialog.vue")
  },
  {
    name: "marketing-content-management-components-dialogs-preview-DisplayAdPreviewDialog___de",
    path: "/marketing/content-management/components/dialogs/preview/DisplayAdPreviewDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/components/dialogs/preview/DisplayAdPreviewDialog.vue")
  },
  {
    name: "marketing-content-management___en",
    path: "/en/marketing/content-management",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/index.vue")
  },
  {
    name: "marketing-content-management___de",
    path: "/marketing/content-management",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/content-management/index.vue")
  },
  {
    name: "marketing-customer-mails___en",
    path: "/en/marketing/customer-mails",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/customer-mails.vue")
  },
  {
    name: "marketing-customer-mails___de",
    path: "/marketing/customer-mails",
    component: () => import("/app/anybill-partner-portal-v3/pages/marketing/customer-mails.vue")
  },
  {
    name: "onboard___en",
    path: "/en/onboard",
    component: () => import("/app/anybill-partner-portal-v3/pages/onboard/index.vue")
  },
  {
    name: "onboard___de",
    path: "/onboard",
    component: () => import("/app/anybill-partner-portal-v3/pages/onboard/index.vue")
  },
  {
    name: "packages-components-dialogs-BookingDialog___en",
    path: "/en/packages/components/dialogs/BookingDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/dialogs/BookingDialog.vue")
  },
  {
    name: "packages-components-dialogs-BookingDialog___de",
    path: "/packages/components/dialogs/BookingDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/dialogs/BookingDialog.vue")
  },
  {
    name: "packages-components-dialogs-BookingSuccessDialog___en",
    path: "/en/packages/components/dialogs/BookingSuccessDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/dialogs/BookingSuccessDialog.vue")
  },
  {
    name: "packages-components-dialogs-BookingSuccessDialog___de",
    path: "/packages/components/dialogs/BookingSuccessDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/dialogs/BookingSuccessDialog.vue")
  },
  {
    name: "packages-components-dialogs-PackagesAddressDialog___en",
    path: "/en/packages/components/dialogs/PackagesAddressDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/dialogs/PackagesAddressDialog.vue")
  },
  {
    name: "packages-components-dialogs-PackagesAddressDialog___de",
    path: "/packages/components/dialogs/PackagesAddressDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/dialogs/PackagesAddressDialog.vue")
  },
  {
    name: "packages-components-dialogs-PackagesChangePaymentDialog___en",
    path: "/en/packages/components/dialogs/PackagesChangePaymentDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/dialogs/PackagesChangePaymentDialog.vue")
  },
  {
    name: "packages-components-dialogs-PackagesChangePaymentDialog___de",
    path: "/packages/components/dialogs/PackagesChangePaymentDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/dialogs/PackagesChangePaymentDialog.vue")
  },
  {
    name: "packages-components-dialogs-PackagesFeatureList___en",
    path: "/en/packages/components/dialogs/PackagesFeatureList",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/dialogs/PackagesFeatureList.vue")
  },
  {
    name: "packages-components-dialogs-PackagesFeatureList___de",
    path: "/packages/components/dialogs/PackagesFeatureList",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/dialogs/PackagesFeatureList.vue")
  },
  {
    name: "packages-components-table-content-IndividualPricingCard___en",
    path: "/en/packages/components/table/content/IndividualPricingCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/table/content/IndividualPricingCard.vue")
  },
  {
    name: "packages-components-table-content-IndividualPricingCard___de",
    path: "/packages/components/table/content/IndividualPricingCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/table/content/IndividualPricingCard.vue")
  },
  {
    name: "packages-components-table-PricingComparisonTable___en",
    path: "/en/packages/components/table/PricingComparisonTable",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/table/PricingComparisonTable.vue")
  },
  {
    name: "packages-components-table-PricingComparisonTable___de",
    path: "/packages/components/table/PricingComparisonTable",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/table/PricingComparisonTable.vue")
  },
  {
    name: "packages-components-table-PricingToggleSwitch___en",
    path: "/en/packages/components/table/PricingToggleSwitch",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/table/PricingToggleSwitch.vue")
  },
  {
    name: "packages-components-table-PricingToggleSwitch___de",
    path: "/packages/components/table/PricingToggleSwitch",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/components/table/PricingToggleSwitch.vue")
  },
  {
    name: "packages___en",
    path: "/en/packages",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/index.vue")
  },
  {
    name: "packages___de",
    path: "/packages",
    component: () => import("/app/anybill-partner-portal-v3/pages/packages/index.vue")
  },
  {
    name: "privacy-components-AnybillLogo___en",
    path: "/en/privacy/components/AnybillLogo",
    component: () => import("/app/anybill-partner-portal-v3/pages/privacy/components/AnybillLogo.vue")
  },
  {
    name: "privacy-components-AnybillLogo___de",
    path: "/privacy/components/AnybillLogo",
    component: () => import("/app/anybill-partner-portal-v3/pages/privacy/components/AnybillLogo.vue")
  },
  {
    name: "privacy-components-PrivacyContentDe___en",
    path: "/en/privacy/components/PrivacyContentDe",
    component: () => import("/app/anybill-partner-portal-v3/pages/privacy/components/PrivacyContentDe.vue")
  },
  {
    name: "privacy-components-PrivacyContentDe___de",
    path: "/privacy/components/PrivacyContentDe",
    component: () => import("/app/anybill-partner-portal-v3/pages/privacy/components/PrivacyContentDe.vue")
  },
  {
    name: "privacy-components-PrivacyContentEn___en",
    path: "/en/privacy/components/PrivacyContentEn",
    component: () => import("/app/anybill-partner-portal-v3/pages/privacy/components/PrivacyContentEn.vue")
  },
  {
    name: "privacy-components-PrivacyContentEn___de",
    path: "/privacy/components/PrivacyContentEn",
    component: () => import("/app/anybill-partner-portal-v3/pages/privacy/components/PrivacyContentEn.vue")
  },
  {
    name: "privacy___en",
    path: "/en/privacy",
    meta: indexnewfnpMSUPMeta || {},
    component: () => import("/app/anybill-partner-portal-v3/pages/privacy/index.vue")
  },
  {
    name: "privacy___de",
    path: "/privacy",
    meta: indexnewfnpMSUPMeta || {},
    component: () => import("/app/anybill-partner-portal-v3/pages/privacy/index.vue")
  },
  {
    name: "profile-components-AddressProfileCard___en",
    path: "/en/profile/components/AddressProfileCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/profile/components/AddressProfileCard.vue")
  },
  {
    name: "profile-components-AddressProfileCard___de",
    path: "/profile/components/AddressProfileCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/profile/components/AddressProfileCard.vue")
  },
  {
    name: "profile-components-dialogs-VendorDialog___en",
    path: "/en/profile/components/dialogs/VendorDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/profile/components/dialogs/VendorDialog.vue")
  },
  {
    name: "profile-components-dialogs-VendorDialog___de",
    path: "/profile/components/dialogs/VendorDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/profile/components/dialogs/VendorDialog.vue")
  },
  {
    name: "profile-components-PaymentProfileCard___en",
    path: "/en/profile/components/PaymentProfileCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/profile/components/PaymentProfileCard.vue")
  },
  {
    name: "profile-components-PaymentProfileCard___de",
    path: "/profile/components/PaymentProfileCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/profile/components/PaymentProfileCard.vue")
  },
  {
    name: "profile-components-VendorProfileCard___en",
    path: "/en/profile/components/VendorProfileCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/profile/components/VendorProfileCard.vue")
  },
  {
    name: "profile-components-VendorProfileCard___de",
    path: "/profile/components/VendorProfileCard",
    component: () => import("/app/anybill-partner-portal-v3/pages/profile/components/VendorProfileCard.vue")
  },
  {
    name: "profile___en",
    path: "/en/profile",
    component: () => import("/app/anybill-partner-portal-v3/pages/profile/index.vue")
  },
  {
    name: "profile___de",
    path: "/profile",
    component: () => import("/app/anybill-partner-portal-v3/pages/profile/index.vue")
  },
  {
    name: "settings-components-dialogs-ChangePasswordDialog___en",
    path: "/en/settings/components/dialogs/ChangePasswordDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/settings/components/dialogs/ChangePasswordDialog.vue")
  },
  {
    name: "settings-components-dialogs-ChangePasswordDialog___de",
    path: "/settings/components/dialogs/ChangePasswordDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/settings/components/dialogs/ChangePasswordDialog.vue")
  },
  {
    name: "settings-components-SettingsPage___en",
    path: "/en/settings/components/SettingsPage",
    component: () => import("/app/anybill-partner-portal-v3/pages/settings/components/SettingsPage.vue")
  },
  {
    name: "settings-components-SettingsPage___de",
    path: "/settings/components/SettingsPage",
    component: () => import("/app/anybill-partner-portal-v3/pages/settings/components/SettingsPage.vue")
  },
  {
    name: "settings___en",
    path: "/en/settings",
    component: () => import("/app/anybill-partner-portal-v3/pages/settings/index.vue")
  },
  {
    name: "settings___de",
    path: "/settings",
    component: () => import("/app/anybill-partner-portal-v3/pages/settings/index.vue")
  },
  {
    name: "store-management-dialogs-AddStoreDialog___en",
    path: "/en/store-management/dialogs/AddStoreDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/store-management/dialogs/AddStoreDialog.vue")
  },
  {
    name: "store-management-dialogs-AddStoreDialog___de",
    path: "/store-management/dialogs/AddStoreDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/store-management/dialogs/AddStoreDialog.vue")
  },
  {
    name: "store-management-dialogs-Step1___en",
    path: "/en/store-management/dialogs/Step1",
    component: () => import("/app/anybill-partner-portal-v3/pages/store-management/dialogs/Step1.vue")
  },
  {
    name: "store-management-dialogs-Step1___de",
    path: "/store-management/dialogs/Step1",
    component: () => import("/app/anybill-partner-portal-v3/pages/store-management/dialogs/Step1.vue")
  },
  {
    name: "store-management-dialogs-Step2___en",
    path: "/en/store-management/dialogs/Step2",
    component: () => import("/app/anybill-partner-portal-v3/pages/store-management/dialogs/Step2.vue")
  },
  {
    name: "store-management-dialogs-Step2___de",
    path: "/store-management/dialogs/Step2",
    component: () => import("/app/anybill-partner-portal-v3/pages/store-management/dialogs/Step2.vue")
  },
  {
    name: "store-management-dialogs-Step3___en",
    path: "/en/store-management/dialogs/Step3",
    component: () => import("/app/anybill-partner-portal-v3/pages/store-management/dialogs/Step3.vue")
  },
  {
    name: "store-management-dialogs-Step3___de",
    path: "/store-management/dialogs/Step3",
    component: () => import("/app/anybill-partner-portal-v3/pages/store-management/dialogs/Step3.vue")
  },
  {
    name: "store-management-dialogs-Step4___en",
    path: "/en/store-management/dialogs/Step4",
    component: () => import("/app/anybill-partner-portal-v3/pages/store-management/dialogs/Step4.vue")
  },
  {
    name: "store-management-dialogs-Step4___de",
    path: "/store-management/dialogs/Step4",
    component: () => import("/app/anybill-partner-portal-v3/pages/store-management/dialogs/Step4.vue")
  },
  {
    name: "store-management___en",
    path: "/en/store-management",
    component: () => import("/app/anybill-partner-portal-v3/pages/store-management/index.vue")
  },
  {
    name: "store-management___de",
    path: "/store-management",
    component: () => import("/app/anybill-partner-portal-v3/pages/store-management/index.vue")
  },
  {
    name: "user-administration-dialogs-EditRightsGroupsDialog___en",
    path: "/en/user-administration/dialogs/EditRightsGroupsDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/user-administration/dialogs/EditRightsGroupsDialog.vue")
  },
  {
    name: "user-administration-dialogs-EditRightsGroupsDialog___de",
    path: "/user-administration/dialogs/EditRightsGroupsDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/user-administration/dialogs/EditRightsGroupsDialog.vue")
  },
  {
    name: "user-administration-dialogs-EditUserRightsDialog___en",
    path: "/en/user-administration/dialogs/EditUserRightsDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/user-administration/dialogs/EditUserRightsDialog.vue")
  },
  {
    name: "user-administration-dialogs-EditUserRightsDialog___de",
    path: "/user-administration/dialogs/EditUserRightsDialog",
    component: () => import("/app/anybill-partner-portal-v3/pages/user-administration/dialogs/EditUserRightsDialog.vue")
  },
  {
    name: "user-administration___en",
    path: "/en/user-administration",
    component: () => import("/app/anybill-partner-portal-v3/pages/user-administration/index.vue")
  },
  {
    name: "user-administration___de",
    path: "/user-administration",
    component: () => import("/app/anybill-partner-portal-v3/pages/user-administration/index.vue")
  }
]