

export const useCropModule = defineStore("crop", () => {
  const toManipulate = ref<string | null>(null);

  function manipulate(newImg: string) {
    toManipulate.value = newImg;
  }

  function reset() {
    toManipulate.value = null;
  }

  return { toManipulate, manipulate, reset };
});
