import type { AnybillHeaders } from "~/models/interfaces/AnybillHeaders";

export type AnybillResult<T> = | {
  success: true;
  statusCode: number;
  value: T;
  headers?: AnybillHeaders;
} | {
  success: false;
  statusCode: number;
  errorMessage: string | null;
  errorCode: string | null;
  errorType: AnybillErrorType;
};

export class AnybillResultHelper {
  static success = <T>(data: T, statusCode: number, headers?: AnybillHeaders): AnybillResult<T> => { return { success: true, statusCode, value: data, headers }; };

  static failure = <T>(message: string | null, statusCode: number, errorCode: string | null): AnybillResult<T> => { return { success: false, statusCode, errorMessage: message, errorCode, errorType: getErrorType(statusCode) }; };
}

export enum AnybillErrorType {
  /// This error type is used for generic errors that do not fit into any other category.
  genericError,

  /// This error type is used when there is a network error while making a request.
  networkError,

  /// This error type is used when there is a server error.
  serverError,

  /// This error type is used when there is an authorization error.
  unauthorizedError,

  /// This error type is used when the issue can't be defined. Typically a critcal error.
  unknown,
}

function getErrorType(statusCode: number): AnybillErrorType {
  if (statusCode === 401)
    return AnybillErrorType.unauthorizedError;
  else if (statusCode >= 400 && statusCode < 500)
    return AnybillErrorType.genericError;
  else if (statusCode >= 500 && statusCode < 600)
    return AnybillErrorType.serverError;
  else if (statusCode === 0)
    return AnybillErrorType.networkError;

  return AnybillErrorType.unknown;
}
